import React, { useState } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

interface ConfirmResetFormProps {
    onSubmit: (code: string, newPassword: string, email: string) => Promise<boolean>;
    email: string;
    isLoading: boolean;
    error: string | null;
}

const FormContainer = styled(Box)(({ theme }) => ({
    width: '100%',
    maxWidth: '600px'
}));

const ConfirmResetForm: React.FC<ConfirmResetFormProps> = ({
                                                               onSubmit,
                                                               email,
                                                               isLoading,
                                                               error
                                                           }) => {
    const [formData, setFormData] = useState({
        code: '',
        newPassword: '',
        confirmPassword: ''
    });

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (formData.newPassword !== formData.confirmPassword) {
            return;
        }
        await onSubmit(formData.code, formData.newPassword, email);
    };

    return (
        <form onSubmit={handleSubmit}>
            <FormContainer>
                <Typography variant="h5" align="center" gutterBottom>
                    Confirm Password Reset
                </Typography>
                <Typography variant="body2" align="center" sx={{ mb: 3 }}>
                    Enter the code sent to {email} and your new password.
                </Typography>

                <TextField
                    sx={{ '& .MuiOutlinedInput-root': { borderRadius: '25px' } }}
                    margin="normal"
                    required
                    fullWidth
                    id="code"
                    label="Reset Code"
                    name="code"
                    value={formData.code}
                    onChange={(e) => setFormData(prev => ({ ...prev, code: e.target.value }))}
                />

                <TextField
                    sx={{ '& .MuiOutlinedInput-root': { borderRadius: '25px' } }}
                    margin="normal"
                    required
                    fullWidth
                    type="password"
                    id="newPassword"
                    label="New Password"
                    name="newPassword"
                    value={formData.newPassword}
                    onChange={(e) => setFormData(prev => ({ ...prev, newPassword: e.target.value }))}
                />

                <TextField
                    sx={{ '& .MuiOutlinedInput-root': { borderRadius: '25px' } }}
                    margin="normal"
                    required
                    fullWidth
                    type="password"
                    id="confirmPassword"
                    label="Confirm New Password"
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={(e) => setFormData(prev => ({ ...prev, confirmPassword: e.target.value }))}
                    error={formData.newPassword !== formData.confirmPassword}
                    helperText={formData.newPassword !== formData.confirmPassword ? "Passwords don't match" : ''}
                />

                {error && (
                    <Typography color="error" sx={{ mt: 2 }}>
                        {error}
                    </Typography>
                )}

                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, borderRadius: '25px' }}
                    disabled={isLoading}
                >
                    Reset Password
                </Button>
            </FormContainer>
        </form>

    );
};

export default ConfirmResetForm;