import React, {useEffect, useState} from 'react';
import {RegistrationFormData} from '../../types/registration.types';
import {RegisterUser} from '../../types/auth.types';
import {useRegister} from '../../hooks/useRegister';
import RegistrationForm from "../../pages/registration/forms/RegistrationForm";
import ConfirmationForm from "../../pages/registration/forms/ConfirmationForm";
import {LANDING_REGISTRATION_CONFIG, REGISTRATION_PAGE_CONFIG} from "../../constants/registration.constants";
import {GoogleReCaptcha, GoogleReCaptchaProvider} from "react-google-recaptcha-v3";

interface RegistrationFormWrapperProps {
    config: typeof REGISTRATION_PAGE_CONFIG | typeof LANDING_REGISTRATION_CONFIG;
    variant: 'full' | 'landing';
}

const RegistrationFormWrapper: React.FC<RegistrationFormWrapperProps> = ({
                                                                             config,
                                                                             variant
                                                                         }) => {
    const {
        register,
        confirmRegistration,
        resendConfirmationCode,
        isLoading,
        error,
        isConfirmationRequired,
        registeredEmail,
    } = useRegister();
    const [captchaToken, setCaptchaToken] = useState<string | null>(null);
    const [shouldVerifyCaptcha, setShouldVerifyCaptcha] = useState(false);
    const [formDataToSubmit, setFormDataToSubmit] = useState<RegistrationFormData | null>(null);
    const [confirmationCodeToSubmit, setConfirmationCodeToSubmit] = useState<string | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);


    //TODO: Deal with captcha requesting loop

    // const handleSubmit = async (formData: RegistrationFormData) => {
    //     setShouldVerifyCaptcha(true);
    //     if (!isConfirmationRequired && captchaToken) {
    //         try {
    //             const registrationData: RegisterUser = {
    //                 email: formData.email,
    //                 password: formData.password,
    //                 firstName: formData.firstName,
    //                 lastName: formData.lastName,
    //                 phoneNumber: formData.phoneNumber,
    //                 professionalAffiliation: formData.professionalAffiliation.toString(),
    //                 title: formData.title.toString(),
    //                 isCertifiedMedical: formData.isCertifiedMedical.toString(),
    //                 agreeToPrivacyPolicy: formData.agreeToPrivacyPolicy.toString(),
    //                 recaptchaToken: captchaToken,
    //             };
    //
    //             await register(registrationData);
    //             setCaptchaToken(null);
    //         } catch (error) {
    //             console.error('Registration error:', error);
    //         }
    //     }
    // };
    //
    // const handleConfirmation = async (code: string) => {
    //     setShouldVerifyCaptcha(true);
    //
    //     if (!captchaToken) return;
    //     try {
    //         await confirmRegistration({
    //             email: registeredEmail,
    //             code
    //         }, captchaToken);
    //         setCaptchaToken(null);
    //     } catch (error) {
    //         console.error('Confirmation error:', error);
    //     }
    // };

    // Handle registration submission
    useEffect(() => {
        const submitRegistration = async () => {
            if (captchaToken && formDataToSubmit && !isSubmitting) {
                try {
                    setIsSubmitting(true);
                    const registrationData: RegisterUser = {
                        ...formDataToSubmit,
                        professionalAffiliation: formDataToSubmit.professionalAffiliation.toString(),
                        title: formDataToSubmit.title.toString(),
                        isCertifiedMedical: formDataToSubmit.isCertifiedMedical.toString(),
                        agreeToPrivacyPolicy: formDataToSubmit.agreeToPrivacyPolicy.toString(),
                        recaptchaToken: captchaToken,
                    };
                    await register(registrationData);
                } catch (error) {
                    console.error('Registration error:', error);
                } finally {
                    setCaptchaToken(null);
                    setFormDataToSubmit(null);
                    setIsSubmitting(false);
                }
            }
        };
        submitRegistration();
    }, [captchaToken, formDataToSubmit, isSubmitting, register]);

    // Handle confirmation submission
    useEffect(() => {
        const submitConfirmation = async () => {
            if (captchaToken && confirmationCodeToSubmit && !isSubmitting) {
                try {
                    setIsSubmitting(true);
                    await confirmRegistration(
                        {
                            email: registeredEmail,
                            code: confirmationCodeToSubmit,
                        },
                        captchaToken
                    );
                } catch (error) {
                    console.error('Confirmation error:', error);
                } finally {
                    setCaptchaToken(null);
                    setConfirmationCodeToSubmit(null);
                    setIsSubmitting(false);
                }
            }
        };
        submitConfirmation();
    }, [captchaToken, confirmationCodeToSubmit, isSubmitting, confirmRegistration, registeredEmail]);

    const handleSubmit = (formData: RegistrationFormData) => {
        if (isSubmitting) return;
        setFormDataToSubmit(formData);
        setShouldVerifyCaptcha(true);
    };

    const handleConfirmation = (code: string) => {
        if (isSubmitting) return;
        setConfirmationCodeToSubmit(code);
        setShouldVerifyCaptcha(true);
    };

    const handleReCaptchaVerify = (token: string) => {
        setCaptchaToken(token);
        setShouldVerifyCaptcha(false);
    };

    const handleResendCode = async () => {
        await resendConfirmationCode(registeredEmail);
    };

    return !isConfirmationRequired ? (
        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_CAPTCHA!}>
            <RegistrationForm
                config={config}
                onSubmit={handleSubmit}
                isLoading={isLoading}
                error={error}
            />
            {shouldVerifyCaptcha && (
                <GoogleReCaptcha onVerify={handleReCaptchaVerify}/>
            )}
        </GoogleReCaptchaProvider>
    ) : (
        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_CAPTCHA!}>
            <ConfirmationForm
                onSubmit={(code) => handleConfirmation(code)}
                onResendCode={handleResendCode}
                isLoading={isLoading}
                error={error}
                email={registeredEmail}
            />
            {shouldVerifyCaptcha && (
                <GoogleReCaptcha onVerify={handleReCaptchaVerify}/>
            )}
        </GoogleReCaptchaProvider>
    );
};

export default RegistrationFormWrapper;