import React, {useEffect} from 'react';
import {Provider, useDispatch} from 'react-redux';
import {store} from "./store/store";
import {setStoreDispatch} from "./services/api/axios.config";
import {BrowserRouter as Router} from 'react-router-dom';
import {ThemeProvider} from '@mui/material/styles';
import {theme} from './themes/theme';
import CssBaseline from '@mui/material/CssBaseline';
import AppProviderWrapper from './AppProvider';
import AppRoutes from './AppRoutes';

const DispatchInitializer: React.FC = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        setStoreDispatch(dispatch);
    }, [dispatch]);

    return null;
};

const App: React.FC = () => {
    return (
        <Provider store={store}>
            <DispatchInitializer/>
            <Router>
                <ThemeProvider theme={theme}>
                    <CssBaseline/>
                    <AppProviderWrapper>
                        <AppRoutes/>
                    </AppProviderWrapper>
                </ThemeProvider>
            </Router>
        </Provider>

    );
};

export default App;
