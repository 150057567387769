import {ResearchQuery} from "../../types/dashboard.types";
import {CRM_API_ENDPOINTS} from "../../constants/crm.constants";
import protectedApi from "../api/axios.config";

export const crmApi = {
    async submitQuery(query: ResearchQuery): Promise<any> {
        const userData = JSON.parse(localStorage.getItem('user_data') || '{}');

        const formData = new FormData();

        formData.append('query', query.query);
        formData.append('age', query.age);
        formData.append('sex', query.sex);
        formData.append('mainProblem', query.mainProblem);
        formData.append('relevantInformation', query.relevantInformation || 'Not provided');
        formData.append('pastTreatments', query.pastTreatments || 'Not provided');
        formData.append('currentTreatments', query.currentTreatments || 'Not provided');
        formData.append('medicalHistory', query.medicalHistory || 'Not provided');

        formData.append('fullName', `${userData.given_name} ${userData.family_name}`);
        formData.append('email', userData.email);
        formData.append('userId', userData.sub);

        if (query.files && query.files.length > 0) {
            query.files.forEach(file => {
                formData.append('files', file);
            });
        }

        const response = await protectedApi.post(CRM_API_ENDPOINTS.DASHBOARD, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
        return response.data;
    },

};