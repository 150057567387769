import React, {useEffect, useState} from 'react';
import {
    Box, Button, CircularProgress, FormControl, FormControlLabel, RadioGroup, Radio,
    Paper,
    TextField,
    Typography,
} from '@mui/material';
import {ResearchQuery} from '../../../types/dashboard.types';
import FileUploadComponent from "../components/FileUploader";
import FeedbackModal from "../../../components/shared/FeedbackModal";
import {ArrowForward} from '@mui/icons-material';
import {styled} from "@mui/material/styles";

interface QueryFormProps {
    onSubmit: (query: ResearchQuery) => void;
}

const FormContainer = styled(Paper)(({theme}) => ({
    padding: theme.spacing(4),
    borderRadius: 16,
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.05)",
    transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
    "&:hover": {
        boxShadow: "0 6px 25px rgba(0, 0, 0, 0.1)",
    },
    "& .MuiTypography-h6": {
        fontWeight: 600,
        marginBottom: theme.spacing(1),
        color: theme.palette.primary.main,
    },
    "& .MuiTypography-body2": {
        marginBottom: theme.spacing(3),
        color: theme.palette.text.secondary,
    },
}));

const StyledTextField = styled(TextField)(({theme}) => ({
    "& .MuiOutlinedInput-root": {
        borderRadius: 8,
        transition: "all 0.2s ease-in-out",
        "&:hover": {
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: theme.palette.primary.main,
            },
        },
        "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
                borderWidth: 2,
            },
        },
    },
    "& .MuiInputLabel-root": {
        "&.Mui-focused": {
            color: theme.palette.primary.main,
        },
    },
    "& .MuiInputBase-multiline": {
        padding: theme.spacing(1),
    },
}));

const RadioGroupContainer = styled(FormControl)(({theme}) => ({
    "& .MuiFormGroup-root": {
        marginTop: theme.spacing(1),
    },
    "& .MuiRadio-root": {
        color: theme.palette.text.secondary,
        "&.Mui-checked": {
            color: theme.palette.primary.main,
        },
    },
    "& .MuiFormControlLabel-root": {
        marginRight: theme.spacing(3),
    },
}));

const TopFormSection = styled(Box)(({theme}) => ({
    display: "flex",
    gap: theme.spacing(3),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        gap: theme.spacing(2),
    },
}));

const SubmitButtonContainer = styled(Box)(({theme}) => ({
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(4),
    "& .MuiButton-root": {
        borderRadius: 8,
        padding: theme.spacing(1, 4),
        textTransform: "none",
        fontWeight: 500,
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        transition: "all 0.2s ease-in-out",
        "&:hover": {
            boxShadow: "0 6px 16px rgba(0, 0, 0, 0.15)",
            transform: "translateY(-1px)",
        },
        "&:disabled": {
            backgroundColor: theme.palette.action.disabledBackground,
            color: theme.palette.action.disabled,
        },
    },
}));

const QueryForm: React.FC<QueryFormProps> = ({onSubmit}) => {
    const [queryData, setQueryData] = useState<ResearchQuery>({
        query: '',
        age: '',
        sex: '',
        mainProblem: '',
        relevantInformation: '',
        currentTreatments: '',
        pastTreatments: '',
        medicalHistory: '',
        files: []
    });
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        try {
            const pendingQuery = localStorage.getItem('pendingQuery');
            if (pendingQuery) {
                const parsedQuery = JSON.parse(pendingQuery);

                setQueryData(prev => ({
                    ...prev,
                    query: parsedQuery.query,
                    mainProblem: parsedQuery.mainProblem,
                }));

                setFeedbackState({
                    isOpen: true,
                    type: 'info',
                    title: 'Additional Information Required',
                    message: 'To provide you with the most accurate response, please fill in additional details about your inquiry if possible.'
                });
            }

        } catch (error) {
            console.error('Error parsing pending query:', error);
        }
    }, []);

    const [feedbackState, setFeedbackState] = useState<{
        isOpen: boolean;
        type: 'success' | 'error';
        title: string;
        message: string;
    }>({
        isOpen: false,
        type: 'success',
        title: '',
        message: ''
    });

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setQueryData(prev => ({
                ...prev,
                files: prev.files
                    ? [...prev.files, ...Array.from(e.target.files!)]
                    : Array.from(e.target.files!)
            }));
            // Reset the input value to allow uploading the same file again
            e.target.value = '';
        }
    };


    const removeFile = (indexToRemove: number) => {
        setQueryData(prev => ({
            ...prev,
            files: prev.files?.filter((_, index) => index !== indexToRemove) || []
        }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!queryData.query.trim()) {
            setFeedbackState({
                isOpen: true,
                type: 'error',
                title: 'Validation Error',
                message: 'Query field cannot be empty'
            });
            return;
        }

        try {
            setIsSubmitting(true);

            await onSubmit(queryData);
            setFeedbackState({
                isOpen: true,
                type: 'success',
                title: 'Query Received',
                message: 'Thank you for submitting your medical question. Our team is reviewing it and will provide an evidence-based response as soon as possible.'
            });
            // Reset form on success
            setQueryData({
                query: '',
                files: [],
                age: '',
                sex: '',
                mainProblem: '',
                relevantInformation: '',
                currentTreatments: '',
                pastTreatments: '',
                medicalHistory: ''
            });
        } catch (error) {
            setFeedbackState({
                isOpen: true,
                type: 'error',
                title: 'Submission Error',
                message: 'Failed to submit query. Please try again.'
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleModalClose = () => {
        setFeedbackState(prev => ({...prev, isOpen: false}));
        localStorage.removeItem('pendingQuery');
    };

    return (
        <>
            <FormContainer>
                <Typography variant="h6" gutterBottom>
                    Submit Your Query
                </Typography>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                    Provide your clinical dilemma or specify a topic for research.
                </Typography>

                <form onSubmit={handleSubmit}>
                    <TopFormSection>
                        <StyledTextField
                            label="Age"
                            value={queryData.age}
                            onChange={(e) => setQueryData(prev => ({
                                ...prev,
                                age: e.target.value
                            }))}
                            sx={{width: '120px'}}
                        />

                        <RadioGroupContainer>
                            <RadioGroup
                                row
                                value={queryData.sex}
                                onChange={(e) => setQueryData(prev => ({
                                    ...prev,
                                    sex: e.target.value as 'Male' | 'Female'
                                }))}
                            >
                                <FormControlLabel value="Male" control={<Radio/>} label="Male"/>
                                <FormControlLabel value="Female" control={<Radio/>} label="Female"/>
                            </RadioGroup>
                        </RadioGroupContainer>
                    </TopFormSection>

                    <StyledTextField
                        fullWidth
                        label="Main Topic"
                        value={queryData.mainProblem}
                        onChange={(e) => setQueryData(prev => ({
                            ...prev,
                            mainProblem: e.target.value
                        }))}
                        margin="normal"
                        required
                    />

                    <StyledTextField
                        multiline
                        rows={3}
                        fullWidth
                        label="Relevant Information"
                        helperText="Location, severity, duration, mutation, family history, etc."
                        value={queryData.relevantInformation}
                        onChange={(e) => setQueryData(prev => ({
                            ...prev,
                            relevantInformation: e.target.value
                        }))}
                        margin="normal"
                    />

                    <StyledTextField
                        multiline
                        rows={3}
                        fullWidth
                        label="Relevant Medical History"
                        value={queryData.medicalHistory}
                        onChange={(e) => setQueryData(prev => ({
                            ...prev,
                            medicalHistory: e.target.value
                        }))}
                        margin="normal"
                    />

                    <StyledTextField
                        multiline
                        rows={3}
                        fullWidth
                        label="Past Relevant Treatments"
                        value={queryData.pastTreatments}
                        onChange={(e) => setQueryData(prev => ({
                            ...prev,
                            pastTreatments: e.target.value
                        }))}
                        margin="normal"
                    />

                    <StyledTextField
                        multiline
                        rows={3}
                        fullWidth
                        label="Current Treatments"
                        value={queryData.currentTreatments}
                        onChange={(e) => setQueryData(prev => ({
                            ...prev,
                            currentTreatments: e.target.value
                        }))}
                        margin="normal"
                    />


                    <StyledTextField
                        multiline
                        rows={4}
                        fullWidth
                        required
                        label="Provide your Dilemma"
                        value={queryData.query}
                        onChange={(e) => setQueryData(prev => ({
                            ...prev,
                            query: e.target.value
                        }))}
                        margin="normal"
                    />

                    <FileUploadComponent
                        queryData={queryData}
                        handleFileChange={handleFileChange}
                        removeFile={removeFile}
                    />


                    <SubmitButtonContainer>
                        <Button
                            variant="contained"
                            type="submit"
                            endIcon={isSubmitting ? <CircularProgress size={20} color="inherit"/> : <ArrowForward/>}
                            disabled={!queryData.query.trim() || !queryData.mainProblem.trim() || isSubmitting}
                        >
                            {isSubmitting ? 'Submitting...' : 'Submit'}
                        </Button>
                    </SubmitButtonContainer>
                </form>
            </FormContainer>
            <FeedbackModal
                isOpen={feedbackState.isOpen}
                onClose={handleModalClose}
                title={feedbackState.title}
                message={feedbackState.message}
                type={feedbackState.type}
            />
        </>
    );
};

export default QueryForm;