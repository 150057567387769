import React, {useState} from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

interface RequestResetFormProps {
    onSubmit: (email: string) => Promise<boolean>;
    isLoading: boolean;
    error: string | null;
}

const FormContainer = styled(Box)(({ theme }) => ({
    width: '100%',
    maxWidth: '600px'
}));

const RequestResetForm: React.FC<RequestResetFormProps> = ({ onSubmit, isLoading, error }) => {
    const [email, setEmail] = useState('');

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        await onSubmit(email);
    };

    return (
        <form onSubmit={handleSubmit}>
            <FormContainer>
                <Typography variant="h5" align="center" gutterBottom>
                    Reset Password
                </Typography>
                <Typography variant="body2" align="center" sx={{ mb: 3 }}>
                    Enter your email address and we'll send you a code to reset your password.
                </Typography>

                <TextField
                    sx={{ '& .MuiOutlinedInput-root': { borderRadius: '25px' } }}
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    error={!!error}
                    helperText={error}
                />

                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, borderRadius: '25px' }}
                    disabled={isLoading}
                >
                    Send Reset Code
                </Button>
            </FormContainer>
        </form>
    );
};

export default RequestResetForm;