import React, {forwardRef} from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Box,
    Fade,
} from '@mui/material';
import {CheckCircleOutline, ErrorOutline, InfoOutlined} from '@mui/icons-material';
import {TransitionProps} from "@mui/material/transitions";
import {styled} from "@mui/material/styles";


const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        borderRadius: 16,
        padding: theme.spacing(2),
        boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
        border: '1px solid rgba(255, 255, 255, 0.18)',
        backdropFilter: 'blur(8px)',
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(2),
            width: 'calc(100% - 32px)',
            maxWidth: '100%',
        },
        [theme.breakpoints.up('sm')]: {
            minWidth: 400,
            maxWidth: 500,
        },
    },
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
    padding: theme.spacing(2),
    '& .MuiBox-root': {
        gap: theme.spacing(2),
        '& svg': {
            fontSize: 28,
            [theme.breakpoints.down('sm')]: {
                fontSize: 24,
            },
        },
    },
    '& .MuiTypography-root': {
        fontSize: '1.5rem',
        fontWeight: 600,
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.25rem',
        },
    },
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
    padding: theme.spacing(2, 3),
    '& .MuiDialogContentText-root': {
        fontSize: '1rem',
        color: theme.palette.text.secondary,
        marginBottom: 0,
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.875rem',
        },
    },
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
    padding: theme.spacing(2),
    '& .MuiButton-root': {
        borderRadius: 8,
        padding: theme.spacing(1, 3),
        textTransform: 'none',
        fontWeight: 600,
        fontSize: '0.9rem',
        boxShadow: 'none',
        '&:hover': {
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        },
    },
}));

const StyledIconBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    '& svg': {
        filter: 'drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1))',
    },
}));

interface FeedbackModalProps {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    message: string;
    type: 'success' | 'error' | 'info';
}

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return (
        <Fade
            {...props}
            ref={ref}
            timeout={{
                enter: 1500,
                exit: 300
            }}
        >
            {props.children}
        </Fade>
    );
});

const FeedbackModal: React.FC<FeedbackModalProps> = ({
                                                         isOpen,
                                                         onClose,
                                                         title,
                                                         message,
                                                         type
                                                     }) => {
    return (

        <StyledDialog
            open={isOpen}
            onClose={onClose}
            aria-labelledby="feedback-dialog-title"
            TransitionComponent={Transition}
        >
            <StyledDialogTitle id="feedback-dialog-title">
                <StyledIconBox display="flex" alignItems="center" gap={1}>
                    {type === 'success' ? (
                        <CheckCircleOutline color="success"/>
                    ) : type === 'error' ? (
                        <ErrorOutline color="error"/>
                    ) : (
                        <InfoOutlined color="info"/>
                    )}
                    {title}
                </StyledIconBox>
            </StyledDialogTitle>
            <StyledDialogContent>
                <DialogContentText>
                    {message}
                </DialogContentText>
            </StyledDialogContent>
            <StyledDialogActions>
                <Button onClick={onClose} color="primary" autoFocus>
                    Close
                </Button>
            </StyledDialogActions>
        </StyledDialog>

    );
};

export default FeedbackModal;