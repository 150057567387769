import { ArrowForward } from '@mui/icons-material';
import {LoginLandingPageConfig, LoginPageConfig} from "../types/login.types";

export const LOGIN_PAGE_CONFIG: LoginPageConfig = {
    title: {
        main: "Get Started With Medint",
        description: "Medint answers complex clinical dilemmas for physicians based on academic literature and an AI that us being developed"
    },
    button: {
        text: "Log in",
        icon: ArrowForward,
        onClick: () => console.log('Login clicked')
    },
    footer: {
        description: "Don't have an account yet?",
        link_text: "Register and join the Medint community today",
        href: "/register"
    },
    placeholders: {
        email: "youremail@gmail.com",
        password: "••••••••••••"
    },
    labels: {
        rememberMe: "Remember Me",
        forgotPassword: "Forgot your password?"
    },
    header: {
        imagePath: "/medint-logo-full-transparent.png"
    }
};

export const LOGIN_LANDING_PAGE_CONFIG: LoginLandingPageConfig = {
    title: {
        main: "Get Started With Medint",
        description: "Medint answers complex clinical dilemmas for physicians based on academic literature and an AI that us being developed"
    },
    button: {
        text: "Log in",
        icon: ArrowForward,
        onClick: () => console.log('Login clicked')
    },
    placeholders: {
        email: "youremail@gmail.com",
        password: "••••••••••••"
    },
    labels: {
        rememberMe: "Remember Me",
        forgotPassword: "Forgot your password?"
    },
};