import React from 'react';
import { Box } from '@mui/material';
import { RegistrationHeaderProps } from '../../../types/registration.types';
import {useNavigate} from "react-router-dom";

const RegistrationHeader: React.FC<RegistrationHeaderProps> = ({ imagePath }) => {
    const navigate = useNavigate();

    return (
        <Box sx={{ mb: 4 }}>
            <Box
                component="a"
                onClick={() => navigate('/')}
                sx={{ cursor: 'pointer' }}
            >
                <img
                    src={imagePath}
                    alt="Medint Logo"
                    style={{ maxWidth: '200px', height: 'auto' }}
                />
            </Box>
        </Box>
    );
};
export default RegistrationHeader;