export const AUTH_API_ENDPOINTS = {
    LOGIN: '/api/auth/login',
    REGISTER: '/api/auth/register',
    CONFIRMATION: '/api/auth/confirmation',
    RESEND_CONFIRMATION: '/api/auth/resend-code',
    LOGOUT: '/api/auth/logout',
    REFRESH_TOKEN: '/api/auth/refresh-token',
    VERIFY_TOKEN: '/api/auth/verify-token',
    FORGOT_PASSWORD: '/api/auth/reset-password',
    CONFIRM_PASSWORD: '/api/auth/confirm-password-reset',
} as const;
