import {Report} from '../types/reports.type';
import {useState, useRef, useCallback} from 'react';
import {s3Api} from "../services/s3/s3.services";

export const useS3 = () => {
    const [files, setFiles] = useState<Report[]>([]);
    const [htmlContents, setHtmlContents] = useState<Record<string, string>>({});
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);
    const isFetchingRef = useRef(false);

    const fetchMetadata = useCallback(async (userId: string) => {
        if (isFetchingRef.current) return [];

        console.log('[useS3] Fetching metadata...');
        try {
            isFetchingRef.current = true;
            setIsLoading(true);

            const response = await s3Api.listFiles(userId);

            if (response.status === 'success') {
                // Create reports from both PDF and HTML folders
                const reports = response.data.html.map((reportName: string) => ({
                    id: reportName,
                    title: reportName,
                    description: `Report: ${reportName}`,
                    createdAt: new Date().toISOString(),
                    updatedAt: new Date().toISOString(),
                    status: 'published' as const,
                    pdfUrl: '', // Will be fetched on demand
                    htmlPath: `users/${userId}/reports/html/${reportName}`,
                    pdfPath: `users/${userId}/reports/pdf/${reportName}.pdf`,
                    tags: [],
                }));

                return reports;
            }
            return [];
        } catch (err) {
            console.error('[useS3] Error fetching metadata:', err);
            setError(err instanceof Error ? err : new Error('Failed to fetch metadata'));
            return [];
        } finally {
            setIsLoading(false);
            isFetchingRef.current = false;
        }
    }, []);

    const fetchHtmlContent = async (fullPath: string) => {
        if (htmlContents[fullPath]) return htmlContents[fullPath];
        try {
            const response = await s3Api.getHtmlContent(fullPath);
            setHtmlContents(prev => ({...prev, [fullPath]: response}));
            return response;
        } catch (error) {
            console.error(`Error fetching HTML content for ${fullPath}:`, error);
            throw error;
        }
    };


    const fetchPdfUrl = useCallback(async (pdfPath: string) => {
        try {
            const response = await s3Api.getFile(pdfPath);
            const pdfUrl = response.data.url;
            return pdfUrl;

        } catch (err) {
            console.error('[useS3] Error fetching PDF URL for:', pdfPath, err);
            throw err;
        }
    }, []);


    return {
        files,
        isLoading,
        error,
        fetchMetadata,
        fetchPdfUrl,
        fetchHtmlContent,
        htmlContents,
    };
};