import {
    AuthApiResponse, ConfirmationResponse,
    ConfirmRegistration,
    LoginCredentials,
    RegisterUser,
    RegistrationResponse
} from "../../types/auth.types";
import {AUTH_API_ENDPOINTS} from "../../constants/auth.constants.";
import publicApi from "../api/axios.config";

export const authApi = {
    async login(credentials: LoginCredentials): Promise<AuthApiResponse> {
        const response = await publicApi.post<AuthApiResponse>(
            AUTH_API_ENDPOINTS.LOGIN,
            credentials
        );
        return response.data;
    },


    async register(userData: RegisterUser): Promise<AuthApiResponse<RegistrationResponse>> {
        const response = await publicApi.post<AuthApiResponse<RegistrationResponse>>(
            AUTH_API_ENDPOINTS.REGISTER,
            userData
        );
        return response.data;
    },

    async confirmRegistration(data: ConfirmRegistration): Promise<AuthApiResponse<ConfirmationResponse>> {
        const response = await publicApi.post<AuthApiResponse<ConfirmationResponse>>(
            AUTH_API_ENDPOINTS.CONFIRMATION,
            data
        );
        return response.data;
    },

    async resendConfirmationCode(email: string): Promise<AuthApiResponse<ConfirmationResponse>> {
        const response = await publicApi.post<AuthApiResponse<ConfirmationResponse>>(
            AUTH_API_ENDPOINTS.RESEND_CONFIRMATION,
            {email}
        );
        return response.data;
    },

    async logout(): Promise<AuthApiResponse<void>> {
        const response = await publicApi.post<AuthApiResponse<void>>(
            AUTH_API_ENDPOINTS.LOGOUT,
        );
        return response.data;
    },

    async forgotPassword(email: string): Promise<AuthApiResponse> {
        const response = await publicApi.post<AuthApiResponse<void>>(
            AUTH_API_ENDPOINTS.FORGOT_PASSWORD,
            {email:email}
        )
        return response.data;
    },

    async confirmPasswordReset(code: string, newPassword: string, email: string): Promise<AuthApiResponse> {
        const response = await publicApi.post<AuthApiResponse<void>>(
            AUTH_API_ENDPOINTS.CONFIRM_PASSWORD,
            {code: code, newPassword: newPassword, email: email}
        )
        return response.data
    }
};