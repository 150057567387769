import axios from 'axios';
import {store} from "../../store/store";
import {Dispatch} from "@reduxjs/toolkit";

const API_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5001';

export const publicApi = axios.create({
    baseURL: API_URL
});

const protectedApi = axios.create({
    baseURL: API_URL
});

// We'll use this to store the dispatch function
let storeDispatch: Dispatch | null = null;

// function to set dispatch
export const setStoreDispatch = (dispatch: Dispatch) => {
    storeDispatch = dispatch;
};

// Helper to get the token
const getAuthToken = () => {
    return sessionStorage.getItem('auth_token');
};

// Request interceptor
protectedApi.interceptors.request.use(
    (config) => {
        const token = getAuthToken();
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Response interceptor
protectedApi.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            // Clear tokens and user data
            sessionStorage.removeItem('auth_token');
            localStorage.removeItem('user_data');

            // Dispatch logout action to Redux
            if (storeDispatch) {
                storeDispatch({ type: 'auth/logout' });
            }

            // Redirect to login page
            window.location.href = '/login';
        }

        return Promise.reject(error);
    }
);

export default protectedApi;