import protectedApi from "../api/axios.config";
import {ResearchActivity} from "../../types/dashboard.types";
import {METADATA_API_ENDPOINTS} from "../../constants/dynamodb.constants.";

export interface UserMetadata {
    userId: string;
    lastSignIn: string;
    queries: ResearchActivity[];
    totalQueries: number;
    reports: Array<{ id: string; name: string }>;
    reportsCount: number;
    createdAt: string;
    updatedAt: string;
}


export const metadataApi = {
    async getUserMetadata(userId: string): Promise<UserMetadata> {
        const response = await protectedApi.get(METADATA_API_ENDPOINTS.GET_METADATA.replace(':userId', userId));
        return response.data.data;
    },
};