import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {Container, Grid2, Typography, Box, Fab, Tooltip, Alert} from '@mui/material';
import { styled } from '@mui/material/styles';
import ReportCard from './components/ReportCard';
import { REPORTS_PAGE_CONFIG} from "../../../../constants/reports.constants";
import { useS3 } from '../../../../hooks/useS3';
import LoadingCircle from "../../../../components/shared/LoadingCircle";
import {Report} from "../../../../types/reports.type";
import {Refresh} from "@mui/icons-material";

const ReportsContainer = styled(Container)(({ theme }) => ({
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
}));

const StyledFab = styled(Fab)(({ theme }) => ({
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(4),
    transition: 'transform 0.2s ease-in-out, background-color 0.2s ease-in-out',
    "&:hover": {
        transform: 'scale(1.05)',
    },
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
}));


const Reports: React.FC = () => {
    const navigate = useNavigate();
    const { fetchMetadata, fetchPdfUrl} = useS3();
    const [files, setFiles] = useState<Report[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const loadMetadata = async () => {
        const userData = localStorage.getItem('user_data');
        if (!userData) {
            setError('No user data found. Please try refreshing the page.');
            setIsLoading(false);
            return;
        }

        try {
            setIsRefreshing(true);
            setError(null);
            const parsedUser = JSON.parse(userData);
            const newFiles = await fetchMetadata(parsedUser.sub);
            setFiles(newFiles || []);
            if (newFiles?.length) {
                localStorage.setItem('files_metadata', JSON.stringify(newFiles));
            }
        } catch (error) {
            setError('Failed to load reports. Please try refreshing.');
            console.error('Error loading metadata:', error);
        } finally {
            setIsLoading(false);
            setIsRefreshing(false);
        }
    };

    useEffect(() => {
        try {
            const cachedMetadata = localStorage.getItem('files_metadata');
            if (cachedMetadata) {
                const parsed = JSON.parse(cachedMetadata);
                setFiles(Array.isArray(parsed) ? parsed : []);
                setIsLoading(false);
            } else {
                loadMetadata();
            }
        } catch (error) {
            console.error('Error parsing cached metadata:', error);
            loadMetadata();
        }
    }, []);

    const handleRefresh = () => {
        loadMetadata();
    };

    const handleReportClick = (reportId: string) => {
        const fileId = reportId.split('/').pop() || reportId; // Get just the filename
        navigate(`/dashboard/reports/${fileId}`);
    };

    const handleDownload = async (reportId: string) => {
        const report = files.find(f => f.id === reportId);
        if (report) {
            try {
                const pdfUrl = await fetchPdfUrl(report.pdfPath);
                if (pdfUrl) {
                    window.open(pdfUrl, '_blank');
                }
            } catch (error) {
                console.error('Error downloading PDF:', error);
            }
        }
    };

    if (isLoading) {
        return <LoadingCircle />;
    }

    return (
        <ReportsContainer maxWidth="lg">
            {error && (
                <Alert severity="error" sx={{ mb: 3 }}>
                    {error}
                </Alert>
            )}
            <Box sx={{ mb: 4 }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    {REPORTS_PAGE_CONFIG.title}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                    {REPORTS_PAGE_CONFIG.description}
                </Typography>
            </Box>

            <Grid2 container spacing={3}>
                {files.map((file) => (
                    <Grid2 size={{xs:12, sm:6, md:4}} key={file.id}>
                        <ReportCard
                            report={file}
                            onClick={handleReportClick}
                            onDownload={handleDownload}
                        />
                    </Grid2>
                ))}
            </Grid2>

            {files.length === 0 && (
                <Box sx={{ textAlign: 'center', mt: 4 }}>
                    <Typography variant="body1" color="text.secondary">
                        {REPORTS_PAGE_CONFIG.emptyStateMessage}
                    </Typography>
                </Box>
            )}

            <Tooltip title="Refresh reports">
                <StyledFab
                    color="primary"
                    onClick={handleRefresh}
                    disabled={isRefreshing}
                >
                    <Refresh />
                </StyledFab>


            </Tooltip>
        </ReportsContainer>
    );
};

export default Reports;