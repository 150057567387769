import React, {useEffect, useState} from 'react';
import {LoginFormData} from '../../types/login.types';
import LoginForm from "../../pages/login/forms/LoginForm";
import {LOGIN_PAGE_CONFIG, LOGIN_LANDING_PAGE_CONFIG} from "../../constants/login.constants";
import {useNavigate} from "react-router-dom";
import {GoogleReCaptcha, GoogleReCaptchaProvider} from "react-google-recaptcha-v3";

interface LoginWrapperProps {
    config: typeof LOGIN_PAGE_CONFIG | typeof LOGIN_LANDING_PAGE_CONFIG;
    variant: 'full' | 'landing';
    login: (data: LoginFormData) => Promise<boolean>;
    isLoading: boolean;
    error: string | null;
}

const LoginWrapper: React.FC<LoginWrapperProps> = ({
                                                       config,
                                                       variant,
                                                       login,
                                                       isLoading,
                                                       error
                                                   }) => {
    const navigate = useNavigate();
    const [captchaToken, setCaptchaToken] = useState<string | null>(null);
    const [shouldVerifyCaptcha, setShouldVerifyCaptcha] = useState(false);
    const [formDataToSubmit, setFormDataToSubmit] = useState<LoginFormData | null>(null);
    const [isSubmissionInProgress, setIsSubmissionInProgress] = useState(false);

    const handleReCaptchaVerify = (token: string) => {
        setCaptchaToken(token);
        setShouldVerifyCaptcha(false);
    };

    //TODO: Solve issue with infinity captcha requests more gracefully, for now useEffect with a lot states

    // const handleSubmit = async (formData: LoginFormData) => {
    //     try {
    //         setShouldVerifyCaptcha(true);
    //         if (!captchaToken) return;
    //
    //         const success = await login({
    //             email: formData.email,
    //             password: formData.password,
    //             rememberMe: formData.rememberMe,
    //             recaptchaToken: captchaToken,
    //         });
    //
    //         if (success) {
    //             navigate('/dashboard');
    //             setCaptchaToken(null);
    //         }
    //
    //     } catch (error) {
    //         console.error('Login error:', error);
    //     }
    // };

    useEffect(() => {
        const submitWithToken = async () => {

            if (!captchaToken || !formDataToSubmit || isSubmissionInProgress) return;
            try {
                setIsSubmissionInProgress(true);

                const success = await login({
                    ...formDataToSubmit,
                    recaptchaToken: captchaToken,
                });

                if (success) {
                    navigate('/dashboard');
                    setCaptchaToken(null);
                    setFormDataToSubmit(null);
                }
            } catch (error) {
                console.error('Login error:', error);
            } finally {
                setCaptchaToken(null);
                setFormDataToSubmit(null);
                setIsSubmissionInProgress(false);
            }

        };
        submitWithToken();
    }, [captchaToken, formDataToSubmit, isSubmissionInProgress, login, navigate]);

    const handleSubmit = (formData: LoginFormData) => {
        if (isSubmissionInProgress) return;
        setFormDataToSubmit(formData);
        setShouldVerifyCaptcha(true);
    };

    return (
        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_CAPTCHA!}>
            <LoginForm
                config={config}
                onSubmit={handleSubmit}
                isLoading={isLoading || isSubmissionInProgress}
                error={error}
            />
            {shouldVerifyCaptcha && (
                <GoogleReCaptcha onVerify={handleReCaptchaVerify}/>
            )}
        </GoogleReCaptchaProvider>
    );
};

export default LoginWrapper;