import React, { useState, useEffect } from 'react';
import {Grid2, Typography, Paper, List, ListItem, ListItemIcon, ListItemText, Box, Button} from '@mui/material';
import {Info as TipIcon} from '@mui/icons-material';
import { DASHBOARD_CONFIG } from '../../constants/dashboard.constants';
import ResearchProgress from './components/ResearchProgress';
import QueryForm from './forms/DoctorQuery';
import {useCrm} from "../../hooks/useCrm";
import {useMetadata} from "../../hooks/useDynamoDb";
import {styled} from "@mui/material/styles";


export const DefaultStyledBox = styled(Box)(({ theme }) => ({
    paddingBottom: theme.spacing(5),
    padding: theme.spacing(8, 2),

    [theme.breakpoints.down('md')]: {
        padding: theme.spacing(6, 2)
    }}))

export const DashboardContainer = styled(Box)(({ theme }) => ({
    background: "linear-gradient(180deg, #f5f7ff 10%, #ffffff 100%)",
    paddingBottom: theme.spacing(5),
    padding: theme.spacing(8, 2),
    [theme.breakpoints.down("md")]: {
        padding: theme.spacing(6, 2),
    },
}));

export const ContentWrapper = styled(Box)(({ theme }) => ({
    flexGrow: 1,
    margin: "2em",
    [theme.breakpoints.down("sm")]: {
        margin: "1em",
    },
}));

export const WelcomeSection = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(4),
    "& h4": {
        fontWeight: 600,
        marginBottom: theme.spacing(1),
    },
    "& .MuiTypography-subtitle1": {
        color: theme.palette.text.secondary,
        fontSize: "1.1rem",
    },
}));

export const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    height: "100%",
    borderRadius: 16,
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.05)",
    transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
    "&:hover": {
        boxShadow: "0 6px 25px rgba(0, 0, 0, 0.1)",
    },
}));

export const TipsSection = styled(StyledPaper)(({ theme }) => ({
    "& .MuiTypography-h6": {
        fontWeight: 600,
        marginBottom: theme.spacing(2),
        color: theme.palette.primary.main,
    },
}));

export const StyledList = styled(List)(({ theme }) => ({
    "& .MuiListItem-root": {
        padding: theme.spacing(1.5, 0),
    },
    "& .MuiListItemIcon-root": {
        minWidth: 40,
        "& svg": {
            fontSize: 24,
            color: theme.palette.primary.main,
        },
    },
    "& .MuiListItemText-primary": {
        fontSize: "0.95rem",
        color: theme.palette.text.secondary,
    },
}));

export const ActivityCard = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2.5),
    marginBottom: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: 12,
    background: theme.palette.background.paper,
    transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
    "&:hover": {
        boxShadow: "0 6px 25px rgba(0, 0, 0, 0.1)",
    },
}));

export const ActivityContent = styled(Box)(({ theme }) => ({
    "& .MuiTypography-subtitle1": {
        fontWeight: 500,
        marginBottom: theme.spacing(0.5),
    },
    "& .MuiTypography-body2": {
        color: theme.palette.text.secondary,
    },
}));

export const ViewButton = styled(Button)(({ theme }) => ({
    borderRadius: 8,
    padding: theme.spacing(1, 3),
    textTransform: "none",
    fontWeight: 500,
    boxShadow: "none",
    "&:hover": {
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
    },
}));

const Dashboard: React.FC = () => {
    const { handleQuerySubmit } = useCrm();
    const { activities, loading, error, refreshMetadata } = useMetadata();


    const [user, setUser] = useState({
        title: '',
        given_name: '',
        family_name: ''
    });

    useEffect(() => {
        const userData = localStorage.getItem('user_data');
        if (userData) {
            const parsedUser = JSON.parse(userData);
            setUser({
                title: parsedUser.title || '',
                given_name: parsedUser.given_name || '',
                family_name: parsedUser.family_name || ''
            });
        }
    }, []);

    const handleQuerySubmitWithRefresh = async (query: any) => {
        await handleQuerySubmit(query);
        await refreshMetadata();
    };


    return (
        <DashboardContainer >
            <ContentWrapper >
                <Grid2 container spacing={3}>
                    {/* Welcome Section */}
                    <Grid2 size={{xs:12}}>
                        <WelcomeSection>
                            <Typography variant="h4" gutterBottom>
                                Welcome back, {user.title} {user.family_name}
                            </Typography>
                            <Typography variant="subtitle1">
                                {DASHBOARD_CONFIG.welcomeMessage}
                            </Typography>
                        </WelcomeSection>
                    </Grid2>

                    {/* Tips Section */}
                    <Grid2 size={{xs:12, md:4}}>
                        <TipsSection>
                            <Typography variant="h6" gutterBottom>
                                Tips for Better Queries
                            </Typography>
                            <StyledList>
                                {DASHBOARD_CONFIG.tipsList.map((tip, index) => (
                                    <ListItem key={index}>
                                        <ListItemIcon>
                                            <TipIcon color="primary" />
                                        </ListItemIcon>
                                        <ListItemText primary={tip} />
                                    </ListItem>
                                ))}
                            </StyledList>
                        </TipsSection>
                    </Grid2>

                    {/* Query Form Section */}
                    <Grid2 size={{xs:12, md:8}}>
                            <QueryForm
                                onSubmit={handleQuerySubmitWithRefresh}
                            />
                    </Grid2>

                    {/*/!* Research Progress Section *!/*/}
                    {/*<Grid2 size={{xs:12}}>*/}
                    {/*    <ResearchProgress progress={researchProgress} />*/}
                    {/*</Grid2>*/}

                    {/* Research Activity Section */}
                    <Grid2 size={{xs:12}}>
                        <StyledPaper sx={{ p: 2, mt: 2 }}>
                            <Typography variant="h6" gutterBottom>
                                Your Research Activity
                            </Typography>
                            <Box sx={{ mt: 2 }}>
                                {activities.map((activity) => (
                                    <ActivityCard
                                        key={activity.id}
                                        sx={{ p: 2, mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                                    >
                                        <ActivityContent>
                                            <Typography variant="subtitle1">
                                                {activity.query}
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary">
                                                Status: {activity.status}
                                            </Typography>
                                        </ActivityContent>
                                        {activity.status === 'READY' ? (
                                            <ViewButton variant="contained" color="primary">
                                                View Result →
                                            </ViewButton>
                                        ) : (
                                            <Typography variant="body2" color="primary">
                                                In Progress...
                                            </Typography>
                                        )}
                                    </ActivityCard>
                                ))}
                            </Box>
                        </StyledPaper>
                    </Grid2>
                </Grid2>
            </ContentWrapper>
        </DashboardContainer>

    );
};

export default Dashboard;