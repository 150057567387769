import { useState, useEffect } from 'react';
import { ResearchActivity } from '../types/dashboard.types';
import {metadataApi, UserMetadata} from "../services/dynamo-db/dynamodb.services";

export const useMetadata = () => {
    const [metadata, setMetadata] = useState<UserMetadata | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    const fetchMetadata = async () => {
        try {
            setLoading(true);
            const userData = JSON.parse(localStorage.getItem('user_data') || '{}');
            if (!userData.sub) {
                throw new Error('No user ID found');
            }

            const userMetadata = await metadataApi.getUserMetadata(userData.sub);
            setMetadata(userMetadata);
            setError(null);
        } catch (err) {
            setError(err instanceof Error ? err.message : 'Failed to fetch metadata');
            console.error('Failed to fetch metadata:', err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchMetadata();
    }, []);

    const getActivities = (): ResearchActivity[] => {
        if (!metadata) return [];

        return metadata.queries.map(query => ({
            id: query.id,
            query: query.query,
            status: query.status,
            createdAt: query.createdAt,
            completedAt: query.completedAt
        }));
    };

    return {
        metadata,
        loading,
        error,
        activities: getActivities(),
        refreshMetadata: fetchMetadata
    };
};