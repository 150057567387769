import React, {useState} from 'react';
import {
    TextField,
    Button,
    FormControlLabel,
    Checkbox,
    Typography,
    Grid2,
    RadioGroup,
    Radio,
    FormControl,
    FormLabel, Modal, IconButton, Box
} from '@mui/material';
import {RegistrationFormProps, RegistrationFormData} from '../../../types/registration.types';
import 'react-international-phone/style.css';
import {Close as CloseIcon} from "@mui/icons-material";
import {styled} from "@mui/material/styles";
import PrivacyPolicyPage from "../../privacy-terms/PrivacyPolicy";
import {MUIPhoneInput} from "../components/PhoneInput";
import ErrorMessage from "../../../components/shared/Alerts";

const ModalContent = styled(Box)(({theme}) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90vw',
    height: '90vh',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[24],
    borderRadius: theme.spacing(1),
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.down('sm')]: {
        width: '100vw',
        height: '100vh',
        borderRadius: 0,
        top: 0,
        left: 0,
        transform: 'none'
    }
}));

const ModalHeader = styled(Box)(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    position: 'relative',

    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1.5)
    }
}));

const ModalBody = styled(Box)(({theme}) => ({
    flex: 1,
    overflow: 'hidden',
    backgroundColor: theme.palette.background.default,

    '& > div': {
        height: '100%',
        overflow: 'auto',
        WebkitOverflowScrolling: 'touch',
        msOverflowStyle: '-ms-autohiding-scrollbar'
    }
}));


const RegistrationForm: React.FC<RegistrationFormProps> = ({config, onSubmit, isLoading = false, error}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formData, setFormData] = useState<RegistrationFormData>({
        email: '',
        password: '',
        firstName: '',
        lastName: '',
        title: '',
        phoneNumber: '',
        professionalAffiliation: '',
        isCertifiedMedical: false,
        agreeToPrivacyPolicy: false
    });


    const handleOpenPrivacyPolicy = (e: React.MouseEvent) => {
        e.preventDefault();
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };


    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value, checked, type} = event.target;
        setFormData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        onSubmit({
            ...formData,
        });
    };


    return (

        <form onSubmit={handleSubmit} style={{width: '100%', marginTop: '1rem'}}>
            <Typography component="h1" variant="h5" align="center" gutterBottom>
                {config.title.main}
            </Typography>
            <Typography variant="body2" align="center" color="textSecondary" paragraph>
                {config.title.description}
            </Typography>

            <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="legend">How Would You Like to be Addressed</FormLabel>
                <RadioGroup
                    row
                    name="title"
                    value={formData.title}
                    onChange={handleChange}
                >
                    {config.labels.titleOptions.map((title) => (
                        <FormControlLabel
                            key={title}
                            value={title}
                            control={<Radio/>}
                            label={title}
                        />
                    ))}
                </RadioGroup>
            </FormControl>

            <Grid2 container spacing={2}>
                <Grid2 size={{xs: 12, sm: 6}}>
                    <TextField
                        name="firstName"
                        required
                        fullWidth
                        label="First Name"
                        placeholder={config.placeholders.firstName}
                        value={formData.firstName}
                        onChange={handleChange}
                    />
                </Grid2>
                <Grid2 size={{xs: 12, sm: 6}}>
                    <TextField
                        name="lastName"
                        required
                        fullWidth
                        label="Last Name"
                        placeholder={config.placeholders.lastName}
                        value={formData.lastName}
                        onChange={handleChange}
                    />
                </Grid2>
                <Grid2 size={{xs: 12}}>
                    <TextField
                        name="email"
                        required
                        fullWidth
                        label="Email Address"
                        type="email"
                        placeholder={config.placeholders.email}
                        value={formData.email}
                        onChange={handleChange}
                    />
                </Grid2>
                <Grid2 size={{xs: 12}}>
                    <TextField
                        name="password"
                        required
                        fullWidth
                        label="Password"
                        type="password"
                        placeholder={config.placeholders.password}
                        value={formData.password}
                        onChange={handleChange}
                    />
                </Grid2>
                <Grid2 size={{xs: 12}}>
                    <MUIPhoneInput
                        name="phoneNumber"
                        placeholder={config.placeholders.phoneNumber}
                        value={formData.phoneNumber}
                        onChange={(phone) => setFormData(prev => ({...prev, phoneNumber: phone}))}
                    />
                </Grid2>
                <Grid2 size={{xs: 12}}>
                    <TextField
                        name="professionalAffiliation"
                        fullWidth
                        label="Professional Affiliation"
                        placeholder={config.placeholders.professionalAffiliation}
                        value={formData.professionalAffiliation}
                        onChange={handleChange}
                    />
                </Grid2>
                <Grid2 size={{xs: 12,}}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="isCertifiedMedical"
                                checked={formData.isCertifiedMedical}
                                onChange={handleChange}
                                color="primary"
                            />
                        }
                        label={config.labels.certifiedMedical}
                    />
                </Grid2>
                <Grid2 size={{xs: 12}}>
                    <Modal
                        open={isModalOpen}
                        onClose={handleCloseModal}
                        aria-labelledby="privacy-policy-modal"
                    >
                        <ModalContent>
                            <ModalHeader>
                                <Typography variant="h6" component="h2" sx={{flex: 1, pl: 2}}>
                                    Privacy Policy
                                </Typography>
                                <IconButton
                                    onClick={handleCloseModal}
                                    size="large"
                                    sx={{
                                        position: 'absolute',
                                        right: 8,
                                        top: 8
                                    }}
                                >
                                    <CloseIcon/>
                                </IconButton>
                            </ModalHeader>
                            <ModalBody>
                                <Box sx={{
                                    height: '100%',
                                    overflow: 'auto',
                                    padding: {xs: 2, sm: 3, md: 4}
                                }}>
                                    <PrivacyPolicyPage/>
                                </Box>
                            </ModalBody>
                        </ModalContent>
                    </Modal>

                    <Button
                        component="button"
                        variant="text"
                        onClick={handleOpenPrivacyPolicy}
                        sx={{
                            textDecoration: 'underline',
                            '&:hover': {
                                textDecoration: 'underline'
                            },
                            marginBottom: '1rem',
                        }}
                    >
                        {config.labels.showPrivacyPolicy}
                    </Button>

                    <Box>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="agreeToPrivacyPolicy"
                                    checked={formData.agreeToPrivacyPolicy}
                                    onChange={handleChange}
                                    color="primary"
                                    required
                                />
                            }
                            label={config.labels.privacyPolicy}
                        />
                    </Box>


                </Grid2>
            </Grid2>

            <ErrorMessage
                message={error}
            />

            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={isLoading || !formData.agreeToPrivacyPolicy}
                sx={{mt: 3, mb: 2}}
                endIcon={config.button.icon && <config.button.icon/>}
            >
                {isLoading ? 'Processing...' : config.button.text}
            </Button>

        </form>
    );
};

export default RegistrationForm;