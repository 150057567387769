import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RegisterUser, ConfirmRegistration } from '../types/auth.types';
import { authApi } from '../services/auth/auth.services';
import {useAuth} from "./useAuth";


export const useRegister = () => {
    const navigate = useNavigate();
    const { login } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [isConfirmationRequired, setIsConfirmationRequired] = useState(false);
    const [registeredEmail, setRegisteredEmail] = useState<string>('');
    const [registeredPassword, setRegisteredPassword] = useState<string>('');
    const [isRegistering, setIsRegistering] = useState(false);



    const register = async (userData: RegisterUser) => {
        if (isConfirmationRequired || isRegistering) return;

        setIsLoading(true);
        setIsRegistering(true);
        setError(null);

        try {
            const response = await authApi.register(userData);
            setRegisteredEmail(userData.email);
            setRegisteredPassword(userData.password);
            setIsConfirmationRequired(true);
            return response;
        } catch (err: any) {
            setError(err.response?.data?.message || 'Registration failed');
            return false;
        } finally {
            setIsLoading(false);
            setIsRegistering(false);
        }
    };

    const handleAutoLogin = async (loginCaptchaToken: string) => {
        setIsLoading(true);
        try {
            if (!loginCaptchaToken) {
                throw new Error('CAPTCHA token is missing.');
            }
            const loginSuccess = await login({
                email: registeredEmail,
                password: registeredPassword,
                recaptchaToken: loginCaptchaToken,
                rememberMe: true,
            });

            if (loginSuccess) {
                console.log('Auto-login successful.');
                navigate('/dashboard');
            } else {
                console.error('Auto-login failed.');
            }
        } catch (error) {
            console.error('Auto-login error:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const confirmRegistration = async (data: ConfirmRegistration, loginCaptchaToken: string) => {
        setIsLoading(true);
        setError(null);

        try {
            await authApi.confirmRegistration(data);

            const loginSuccess = await handleAutoLogin(loginCaptchaToken);

            if (!loginSuccess) {
                // If auto-login fails, redirect to login page instead
                navigate('/login');
            }
            return true;
        } catch (err: any) {
            setError(err.response?.data?.message || 'Confirmation failed');
            return false;
        } finally {
            setIsLoading(false);
        }
    };

    const resendConfirmationCode = async (email: string) => {
        setIsLoading(true);
        setError(null);

        try {
            await authApi.resendConfirmationCode(email);
            return true;
        } catch (err: any) {
            setError(err.response?.data?.message || 'Failed to resend confirmation code');
            return false;
        } finally {
            setIsLoading(false);
        }
    };

    return {
        register,
        confirmRegistration,
        resendConfirmationCode,
        isLoading,
        error,
        isConfirmationRequired,
        registeredEmail,
        clearError: () => setError(null)
    };
};