import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#4066FF',
        },
        secondary: {
            main: '#2E3A59',
        },

    },
    typography: {
        h1: {
            fontSize: '3.5rem',
            fontWeight: 700,
            lineHeight: 1.2,
        },
        h5: {
            fontWeight: 400,
            lineHeight: 1.5,
        },
    },
    components: {
        MuiContainer:  {
            styleOverrides: {
                root: {
                    padding: '1rem',
                }
            }
        }
    },
    cssVariables: {
        colorSchemeSelector: 'data-toolpad-color-scheme',
    },
    colorSchemes: { light: true, dark: false },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 600,
            lg: 1200,
            xl: 1536,
        },
    },
});