import {jwtDecode} from 'jwt-decode';

interface DecodedToken {
    exp: number;
    user: {
        email: string;
        phone_number: string;
        family_name: string;
        given_name: string;
        title: string;
        professionalAffil: string;
        isCertifiedMedical: string;
        agreeToPrivacyPolicy: string;
    };
}
export const isValidToken = (token: string | null): boolean => {
    if (!token) return false;

    try {
        const decoded = jwtDecode<DecodedToken>(token);
        const currentTime = Date.now() / 1000;

        return decoded.exp > currentTime;
    } catch (error) {
        console.error('Error decoding token:', error);
        return false;
    }
};

export const getStoredAuthState = () => {
    try {
        const token = sessionStorage.getItem('auth_token');
        const userData = localStorage.getItem('user_data');
        if (!token || !userData) {
            return {
                user: null,
                token: null,
                isAuthenticated: false
            };
        }

        // Validate token format
        if (!isValidToken(token)) {
            sessionStorage.removeItem('auth_token');
            localStorage.removeItem('user_data');
            return {
                user: null,
                token: null,
                isAuthenticated: false
            };
        }

        let parsedUserData;
        try {
            parsedUserData = JSON.parse(userData);
        } catch (parseError) {
            console.error('Error parsing user data:', parseError);
            return {
                user: null,
                token: null,
                isAuthenticated: false
            };
        }
        return {
            isAuthenticated: true,
            token,
            user: parsedUserData
        };
    } catch (error) {
        console.error('Authentication state error:', error);
        return {
            user: null,
            token: null,
            isAuthenticated: false
        };
    }
};