import React, {useState} from 'react';
import {
    Box,
    TextField,
    Button,
    FormControlLabel,
    Checkbox,
    InputAdornment,
    IconButton,
} from '@mui/material';
import {styled} from '@mui/material/styles';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import {LoginFormProps, LoginFormData} from '../../../types/login.types';
import {useNavigate} from "react-router-dom";
import ErrorMessage from "../../../components/shared/Alerts";

const FormContainer = styled(Box)(({theme}) => ({
    width: '100%',
    maxWidth: '800px',
    padding: theme.spacing(3),

    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2)
    }
}));

const StyledTextField = styled(TextField)(({theme}) => ({
    '& .MuiOutlinedInput-root': {
        borderRadius: '25px'
    },
    margin: theme.spacing(2, 0),

    [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(1, 0)
    }
}));

const ActionBox = styled(Box)(({theme}) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'stretch',
        gap: theme.spacing(2)
    }
}));

const SubmitButton = styled(Button)(({theme}) => ({
    borderRadius: '25px',
    padding: theme.spacing(1.5, 4),
    minWidth: '150px',
    textTransform: 'uppercase',
    fontSize: '1rem',
    margin: theme.spacing(3, 0),
    backgroundColor: theme.palette.primary.main,
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',

    [theme.breakpoints.down('sm')]: {
        width: '100%',
        order: 3
    }
}));

const ForgotButton = styled(Button)(({theme}) => ({
    textTransform: 'none',

    [theme.breakpoints.down('sm')]: {
        width: '100%',
        order: 2
    }
}));

const RememberMeLabel = styled(FormControlLabel)(({theme}) => ({
    [theme.breakpoints.down('sm')]: {
        order: 1
    }
}));


const LoginForm: React.FC<LoginFormProps> = ({config, onSubmit, isLoading, error}) => {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [formData, setFormData] = useState<LoginFormData>({
        email: '',
        password: '',
        rememberMe: false
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value, checked} = event.target;
        setFormData(prev => ({
            ...prev,
            [name]: name === 'rememberMe' ? checked : value
        }));
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        onSubmit({
            ...formData,
        });
    };

    const handleForgotPassword = (e: React.MouseEvent) => {
        e.preventDefault();
        navigate('/reset-password');
    };

    return (
        <form onSubmit={handleSubmit}>
            <FormContainer>
                <StyledTextField
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '25px'
                        }
                    }}
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={formData.email}
                    onChange={handleChange}
                    placeholder={config.placeholders.email}
                />
                <StyledTextField
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '25px'
                        }
                    }}
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    autoComplete="current-password"
                    value={formData.password}
                    onChange={handleChange}
                    placeholder={config.placeholders.password}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => setShowPassword(!showPassword)}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff/> : <Visibility/>}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <ErrorMessage
                    message={error}
                />
                <ActionBox sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2}}>
                    <RememberMeLabel
                        control={
                            <Checkbox
                                name="rememberMe"
                                checked={formData.rememberMe}
                                onChange={handleChange}
                                color="primary"
                            />
                        }
                        label={config.labels.rememberMe}
                    />
                    <SubmitButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{mt: 3, mb: 2, borderRadius: '25px', maxWidth: '30%'}}
                        disabled={isLoading}
                        endIcon={config.button.icon && <config.button.icon/>}
                    >
                        {config.button.text}
                    </SubmitButton>
                    <ForgotButton
                        onClick={handleForgotPassword}
                        sx={{textTransform: 'none'}}
                        variant="text"
                    >
                        {config.labels.forgotPassword}
                    </ForgotButton>
                </ActionBox>


            </FormContainer>
        </form>
    );
};

export default LoginForm;