import {S3_API_ENDPOINTS} from "../../constants/s3.constants";
import protectedApi from "../api/axios.config";

export const s3Api = {
    async listFiles(userId: string): Promise<any> {
        const response = await protectedApi.post(S3_API_ENDPOINTS.LIST, {
            prefix: userId
        });
        return response.data;
    },

    async getFile(key: string): Promise<any> {
        const encodedKey = encodeURIComponent(key);
        const response = await protectedApi.get(
            S3_API_ENDPOINTS.GET_FILE.replace(':key', encodedKey)
        );
        return response.data;
    },

    async getHtmlContent(key: string): Promise<any> {
        const encodedKey = encodeURIComponent(key);
        const response = await protectedApi.get(
            S3_API_ENDPOINTS.GET_HTML_CONTENT.replace(':key', encodedKey)
        );
        return response.data;
    }
};