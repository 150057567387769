import React, {useEffect} from 'react';
import {Container, Box} from '@mui/material';
import {styled} from '@mui/material/styles';
import LoginHeader from './components/LoginPageHeader';
import LoginFooter from './components/LoginPageFooter';
import {LOGIN_PAGE_CONFIG} from "../../constants/login.constants";
import {useLocation, useNavigate} from "react-router-dom";
import {getStoredAuthState} from "../../services/auth/utils/jwt";
import LoginWrapper from "../../components/shared/LoginFormWrapper";
import {useAuth} from "../../hooks/useAuth";

const LoginContainer = styled(Container)(({theme}) => ({
    marginTop: theme.spacing(8)
}));

const LoginPaper = styled(Box)(({theme}) => ({
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
}));

const Login: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const {login, isLoading, error} = useAuth();

    useEffect(() => {
        const checkAuthStatus = () => {
            const authState = getStoredAuthState();
            if (authState.isAuthenticated) {
                navigate('/dashboard');
            }
        };
        checkAuthStatus();

        // Handle pending query if exists
        const queryParams = new URLSearchParams(location.search);
        const pendingQuery = queryParams.get('pendingQuery');

        if (pendingQuery) {
            try {
                const decodedQuery = JSON.parse(decodeURIComponent(pendingQuery));
                localStorage.setItem('pendingQuery', JSON.stringify(decodedQuery));
            } catch (error) {
                console.error('Error parsing pending query:', error);
            }
        }
    }, [navigate, location]);

    return (
        <LoginContainer maxWidth="sm">
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <LoginPaper>
                    <LoginHeader {...LOGIN_PAGE_CONFIG.header!} />
                    <LoginWrapper
                        config={LOGIN_PAGE_CONFIG}
                        variant="full"
                        login={login}
                        isLoading={isLoading}
                        error={error}
                    />
                    <LoginFooter {...LOGIN_PAGE_CONFIG.footer!} />
                </LoginPaper>
            </Box>
        </LoginContainer>
    );
};


export default Login;