import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { RouteConfig } from './types/route.types';
import {useAuth} from "./hooks/useAuth";
import Login from "./pages/login/Login";
import ResetPassword from "./pages/reset-password/ResetPasswod";
import Dashboard from "./pages/dashboard/Dashboard";
import DashboardWrapper from "./app-layouts/DashboardWrapper";
import Support from "./pages/dashboard/sub-pages/support/Support";
import Reports from "./pages/dashboard/sub-pages/reports/Reports";
import ReportViewer from "./pages/dashboard/sub-pages/reports/components/ReportViewer";
import Registration from "./pages/registration/Registration";


const publicRoutes: RouteConfig[] = [
    {
        path: '/',
        component: Login,
        title: 'Welcome to Medint!'
    },
    {
        path: '/login',
        component: Login,
        title: 'Welcome to Medint!'
    },
    {
        path: '/register',
        component: Registration,
        title: 'Register'
    },
    {
        path: '/reset-password',
        component: ResetPassword,
        title: 'Reset Password'
    },

];


const AppRoutes: React.FC = () => {
    const {isAuthenticated} = useAuth()

    return (
        <Routes>
            {publicRoutes.map(({ path, component: Component }) => (
                <Route
                    key={path}
                    path={path}
                    element={<Component />}
                />
            ))}

            {/* Dashboard Routes */}
            <Route
                path="/dashboard"
                element={
                    isAuthenticated ? (
                        <DashboardWrapper />
                    ) : (
                        <Navigate to="/login" replace state={{ from: '/dashboard' }} />
                    )
                }
            >
                <Route index element={<Dashboard />} />
                <Route path="support" element={<Support />} />
                <Route path="reports" element={<Reports />} />
                <Route path="reports/:reportId" element={<ReportViewer />} />
            </Route>

            <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
    );
};

export default AppRoutes;