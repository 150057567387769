import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import {LoginHeaderProps} from "../../../types/login.types";
import {useNavigate} from "react-router-dom";

const HeaderContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing(4)
}));

const LoginHeader: React.FC<LoginHeaderProps> = ({ imagePath }) => {
    const navigate = useNavigate();

    return (
        <HeaderContainer
            component="a"
            onClick={() => navigate('/')}
            sx={{ cursor: 'pointer' }}
        >

            <img
                src={imagePath}
                alt="Medint Logo"
                style={{ width: '15em', height: 'auto', marginBottom: '2rem' }}
            />
        </HeaderContainer>
    );
};

export default LoginHeader;