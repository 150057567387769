import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useS3} from "../../../../../hooks/useS3";
import {alpha, Box, Fab, Grid2, Tooltip, Typography} from "@mui/material";
import LoadingCircle from "../../../../../components/shared/LoadingCircle";
import HTMLViewer from "../../../../../utils/HtmlViewer";
import {Report} from "../../../../../types/reports.type";
import {styled} from "@mui/material/styles";
import DownloadIcon from '@mui/icons-material/Download';

const StyledFab = styled(Fab)(({theme}) => ({
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(4),
}));
const LoadingWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '60vh',
    gap: theme.spacing(2),
}));

const ViewerContainer = styled(Box)(({ theme }) => ({
    minHeight: '100vh',
    backgroundColor: alpha(theme.palette.background.paper, 0.98),
    position: 'relative',
}));

const ErrorBox = styled(Box)(({ theme }) => ({
    textAlign: 'center',
    padding: theme.spacing(4),
    borderRadius: theme.spacing(2),
    backgroundColor: alpha(theme.palette.error.main, 0.05),
    margin: theme.spacing(3),
}));

const ReportViewer: React.FC = () => {
    const {reportId} = useParams<{ reportId: string }>();
    const {htmlContents, error, fetchHtmlContent, fetchPdfUrl} = useS3();
    const [isLoadingContent, setIsLoadingContent] = useState(false);
    const [files, setFiles] = useState<Report[]>([]);
    const [contentFetched, setContentFetched] = useState(false);

    const selectedFile = files.find(file =>
        file.id.split('/').pop() === reportId
    );

    useEffect(() => {
        const cachedMetadata = localStorage.getItem('files_metadata');
        if (cachedMetadata) {
            setFiles(JSON.parse(cachedMetadata));
        }
    }, []);


    useEffect(() => {
        if (!selectedFile?.id || contentFetched) return;

        const loadHtmlContent = async () => {
            setIsLoadingContent(true);
            try {
                const fullPath = selectedFile.htmlPath;
                await fetchHtmlContent(fullPath);
                setContentFetched(true);
            } finally {
                setIsLoadingContent(false);
            }
        };

        loadHtmlContent();
    }, [selectedFile?.id, contentFetched, fetchHtmlContent]);

    const handleDownload = async () => {
        if (!selectedFile) return;
        try {
            const pdfUrl = await fetchPdfUrl(selectedFile.pdfPath);
            if (pdfUrl) {
                window.open(pdfUrl, '_blank');
            }
        } catch (error) {
            console.error('Error downloading PDF:', error);
        }
    };

    if (!selectedFile) {
        return (
            <ErrorBox>
                <Typography>Report not found: {reportId}</Typography>
            </ErrorBox>
        );
    }

    if (isLoadingContent) {
        return (
            <LoadingWrapper>
                <LoadingCircle/>
            </LoadingWrapper>
        );
    }

    if (error) {
        return (
            <ErrorBox sx={{p: 3, textAlign: 'center'}}>
                <Typography color="error">
                    Error loading report: {error.message}
                </Typography>
            </ErrorBox>
        );
    }

    const htmlContent = htmlContents[selectedFile.htmlPath];


    return (
        <ViewerContainer>
            <HTMLViewer content={htmlContent}/>
            <Grid2>
                <Tooltip title="Download PDF">
                    <StyledFab
                        color="primary"
                        onClick={handleDownload}
                    >
                        <DownloadIcon/>
                    </StyledFab>

                </Tooltip>
            </Grid2>
        </ViewerContainer>
    )
};

export default ReportViewer;