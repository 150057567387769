import { useState } from 'react';
import { authApi } from '../services/auth/auth.services';

interface ResetPasswordState {
    step: 'request' | 'verify' | 'success';
    email: string;
}

export const useResetPassword = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [state, setState] = useState<ResetPasswordState>({
        step: 'request',
        email: ''
    });

    const requestPasswordReset = async (email: string) => {
        setIsLoading(true);
        setError(null);
        try {
            await authApi.forgotPassword(email);
            setState({ step: 'verify', email });
            return true;
        } catch (err: any) {
            setError(err.message || 'Failed to send reset code');
            return false;
        } finally {
            setIsLoading(false);
        }
    };

    const confirmPasswordReset = async (code: string, newPassword: string, email: string) => {
        setIsLoading(true);
        setError(null);
        try {
            await authApi.confirmPasswordReset(code, newPassword, email);
            setState(prev => ({ ...prev, step: 'success' }));
            return true;
        } catch (err: any) {
            setError(err.message || 'Failed to reset password');
            return false;
        } finally {
            setIsLoading(false);
        }
    };

    return {
        isLoading,
        error,
        state,
        requestPasswordReset,
        confirmPasswordReset
    };
};