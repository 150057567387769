import React, { useState, useEffect } from 'react';
import { Alert, AlertTitle, Collapse, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const StyledAlert = styled(Alert)(({ theme }) => ({
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    '& .MuiAlert-message': {
        width: '100%'
    }
}));

interface ErrorField {
    field?: string;
    message: string;
}

interface ErrorResponse {
    status?: string;
    message: string | ErrorField[] | any;
}

interface ErrorMessageProps {
    message: string | null | ErrorField[] | ErrorResponse | any[];
    severity?: 'error' | 'warning' | 'info' | 'success';
    className?: string;
}

const formatFieldName = (field: string): string => {
    if (!field.includes('.')) return field;
    return field.split('.').pop() || field;
};

const parseErrorMessage = (msg: any): ErrorField[] => {
    if (typeof msg === 'string') {
        try {
            const parsed = JSON.parse(msg);
            return Array.isArray(parsed) ? parsed : [{ message: msg }];
        } catch {
            return [{ message: msg }];
        }
    }
    return Array.isArray(msg) ? msg : [{ message: msg?.toString() || 'Unknown error' }];
};

const ErrorMessage: React.FC<ErrorMessageProps> = ({
                                                       message,
                                                       severity = 'error',
                                                       className = ''
                                                   }) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setIsVisible(!!message);
    }, [message]);

    if (!message) return null;

    const formatErrors = (msg: any): string[] => {
        if (typeof msg === 'object' && msg?.message) {
            return formatErrors(msg.message);
        }

        const errors = parseErrorMessage(msg);
        return errors.map(error => error.message);
    };

    const errorMessages = formatErrors(message);

    return (
        <Collapse in={isVisible}>
            <StyledAlert
                severity={severity}
                className={className}
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => setIsVisible(false)}
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
            >
                <AlertTitle>{severity.charAt(0).toUpperCase() + severity.slice(1)}</AlertTitle>
                {errorMessages.map((msg, index) => (
                    <div key={index}>{msg}</div>
                ))}
            </StyledAlert>
        </Collapse>
    );
};

export default ErrorMessage;