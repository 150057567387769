import React from 'react';
import {Container, Box} from '@mui/material';
import {styled} from '@mui/material/styles';
import RegistrationHeader from './components/RegistrationHeader';
import RegistrationFooter from './components/RegistrationFooter';
import {REGISTRATION_PAGE_CONFIG} from "../../constants/registration.constants";
import RegistrationFormWrapper from "../../components/shared/RegistrationFormWrapper";

const RegistrationContainer = styled(Container)(({theme}) => ({
    marginTop: theme.spacing(15)
}));

const RegistrationPaper = styled(Box)(({theme}) => ({
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
}));

const Registration: React.FC = () => {

    return (
        <RegistrationContainer maxWidth="sm">
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <RegistrationPaper>
                    <RegistrationHeader {...REGISTRATION_PAGE_CONFIG.header} />
                    <RegistrationFormWrapper
                        config={REGISTRATION_PAGE_CONFIG}
                        variant="full"
                    />
                    <RegistrationFooter {...REGISTRATION_PAGE_CONFIG.footer} />
                </RegistrationPaper>
            </Box>
        </RegistrationContainer>
    );
};

export default Registration;