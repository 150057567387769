import React, {useState} from 'react';
import {Box, IconButton, List, ListItem, ListItemText, Typography, Paper, alpha} from '@mui/material';
import { Clear as ClearIcon } from '@mui/icons-material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {styled} from "@mui/material/styles";

const UploadContainer = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(2),
    width: '100%',
}));

const UploadArea = styled(Paper, {
    shouldForwardProp: (prop) => prop !== 'isDragging',
})<{ isDragging?: boolean }>(({ theme, isDragging }) => ({
    border: '2px dashed',
    borderColor: isDragging ? theme.palette.primary.main : theme.palette.grey[300],
    borderRadius: 16,
    padding: theme.spacing(4),
    cursor: 'pointer',
    backgroundColor: isDragging
        ? alpha(theme.palette.primary.main, 0.04)
        : theme.palette.background.paper,
    transition: 'all 0.2s ease-in-out',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(2),
    "&:hover": {
        borderColor: theme.palette.primary.main,
        backgroundColor: alpha(theme.palette.primary.main, 0.02),
    },
    "& .MuiSvgIcon-root": {
        fontSize: 48,
        color: isDragging ? theme.palette.primary.main : theme.palette.text.secondary,
        transition: 'color 0.2s ease-in-out',
    },
    "& .MuiTypography-body1": {
        color: theme.palette.text.secondary,
        textAlign: 'center',
    },
}));

const FileListContainer = styled(Paper)(({ theme }) => ({
    marginTop: theme.spacing(2),
    maxHeight: '300px',
    overflow: 'auto',
    borderRadius: 12,
    boxShadow: '0 2px 12px rgba(0, 0, 0, 0.08)',
    "&::-webkit-scrollbar": {
        width: "6px",
    },
    "&::-webkit-scrollbar-track": {
        background: theme.palette.background.paper,
    },
    "&::-webkit-scrollbar-thumb": {
        background: theme.palette.grey[300],
        borderRadius: "3px",
    },
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.divider}`,
    transition: 'background-color 0.2s ease-in-out',
    padding: theme.spacing(0.5, 2), // Reduced vertical padding
    minHeight: '40px', // Set a fixed minimum height
    "&:last-child": {
        borderBottom: 'none',
    },
    "&:hover": {
        backgroundColor: alpha(theme.palette.primary.main, 0.04),
    },
    "& .MuiListItemText-root": {
        margin: 0,
    },
    "& .MuiListItemText-primary": {
        lineHeight: 1.2,
    },
    "& .MuiListItemText-secondary": {
        lineHeight: 1.2,
    },
    "& .MuiTypography-body2": {
        fontWeight: 500,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    "& .MuiTypography-caption": {
        color: theme.palette.text.secondary,
    },
}));

const DeleteButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.error.light,
    transition: 'all 0.2s ease-in-out',
    padding: theme.spacing(0.5),
    "&:hover": {
        backgroundColor: alpha(theme.palette.error.light, 0.1),
        transform: 'scale(1.1)',
    },
}));


const FileUploadComponent = ({ queryData, handleFileChange, removeFile }) => {
    const [isDragging, setIsDragging] = useState(false);

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);

        const files = Array.from(e.dataTransfer.files);
        const event = { target: { files } };
        handleFileChange(event);
    };

    return (
        <UploadContainer>
            {/* Upload Area */}
            <UploadArea
                component="label"
                onDragEnter={handleDragEnter}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}

            >
                <input
                    type="file"
                    hidden
                    multiple
                    onChange={handleFileChange}
                />
                <CloudUploadIcon
                    sx={{
                        fontSize: 48,
                        color: isDragging ? 'primary.main' : 'text.secondary'
                    }}
                />
                <Typography variant="body1" color="text.secondary" align="center">
                    Drag and drop files here or click to browse
                </Typography>
            </UploadArea>

            {/* File List */}
            {queryData.files && queryData.files.length > 0 && (
                <FileListContainer>
                    <List dense>
                        {queryData.files.map((file, index) => (
                            <StyledListItem
                                key={index}
                                secondaryAction={
                                    <DeleteButton
                                        edge="end"
                                        onClick={() => removeFile(index)}
                                        size="small"
                                        sx={{ color: 'error.light' }}
                                    >
                                        <ClearIcon />
                                    </DeleteButton>
                                }
                            >
                                <ListItemText
                                    primary={
                                        <Typography variant="body2" noWrap>
                                            {file.name}
                                        </Typography>
                                    }
                                    secondary={
                                        <Typography variant="caption" color="text.secondary">
                                            {`${(file.size / 1024 / 1024).toFixed(2)} MB`}
                                        </Typography>
                                    }
                                />
                            </StyledListItem>
                        ))}
                    </List>
                </FileListContainer>
            )}

        </UploadContainer>
    );
};

export default FileUploadComponent;