import { ResearchQuery } from '../types/dashboard.types';
import {crmApi} from "../services/crm/crm.service";

export const useCrm = () => {

    const handleQuerySubmit = async (query: ResearchQuery) => {
        try {
            await crmApi.submitQuery(query);
        } catch (error) {
            console.error('Failed to submit query:', error);
            throw error;
            //TODO: Handle error
        }
    };


    return {
        handleQuerySubmit,
    };
};