import {
    QuestionMark as FAQIcon,
    Assignment as QueriesIcon,
    ContactSupport as SupportIcon,
    Dashboard as DashboardIcon
} from '@mui/icons-material';
import { DashboardConfig } from '../types/dashboard.types';

export const DASHBOARD_CONFIG: DashboardConfig = {
    title: "Medint Research Dashboard",
    welcomeMessage: "Submit your query in order to receive a personalized, evidence-based medical response tailored to your patient",
    tipsList: [
        "Sharing information relevant to your patient can assist in generating more tailored and patient-specific answers.",
        "Avoid requesting a complete workup for a certain condition. Instead, focus your questions on the exact dilemmas relevant to your clinical patient.",
    ],
    navigation: [
        {
            title: "Dashboard",
            icon: DashboardIcon,
            path: "/dashboard"
        },
        {
            title: "FAQs",
            icon: FAQIcon,
            path: "/faqs"
        },
        {
            title: "My Previous Queries",
            icon: QueriesIcon,
            path: "/queries"
        },
        {
            title: "Contact Support",
            icon: SupportIcon,
            path: "/support"
        }
    ]
};

export const RESEARCH_STEPS = [
    {
        title: "Gathering Information",
        description: "Collecting relevant medical data and literature"
    },
    {
        title: "Processing Data",
        description: "Analyzing collected information"
    },
    {
        title: "Working on Report",
        description: "Preparing comprehensive research findings"
    },
    {
        title: "Research Ready",
        description: "Final report available for review"
    }
];