import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import DOMPurify from 'dompurify';

interface HTMLViewerProps {
    content: string;
}

const HTMLViewer: React.FC<HTMLViewerProps> = ({ content }) => {
    if (!content) {
        return (
            <Box sx={{ p: 3, textAlign: 'center' }}>
                <Typography color="error">No content available</Typography>
            </Box>
        );
    }

    const sanitizedHtml = DOMPurify.sanitize(content, {
        ADD_TAGS: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'span', 'div', 'ul', 'ol', 'li',
            'table', 'thead', 'tbody', 'tr', 'td', 'th', 'b', 'strong', 'i', 'em',
            'a', 'br', 'img', 'figure', 'figcaption', 'section', 'article'],
        ADD_ATTR: ['class', 'style', 'id', 'href', 'target', 'src', 'alt', 'width', 'height', 'title'],
        FORCE_BODY: true,
        WHOLE_DOCUMENT: true,
        ALLOW_DATA_ATTR: true,
        ALLOW_UNKNOWN_PROTOCOLS: true
    });

    return (
        <Container maxWidth="lg" sx={{ py: 4 }}>
            <Box
                className="html-content"
                dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
                sx={{
                    '& table': {
                        width: '100%',
                        borderCollapse: 'collapse',
                        mb: 3,
                        margin: 'auto'
                    },
                    '& img': {
                        maxWidth: '100%',
                        height: 'auto'
                    },
                    '@media (max-width: 600px)': {
                        '& span[style*="display: inline-block"]': {
                            width: '100% !important',
                            maxWidth: '100% !important'
                        },
                        '& img': {
                            width: '100% !important',
                            height: 'auto !important'
                        }
                    }
                }}
            />
        </Container>
    );
};

export default HTMLViewer;