import React from 'react';
import {Container, Box, Typography, Button} from '@mui/material';
import {styled} from '@mui/material/styles';
import {useResetPassword} from '../../hooks/useResetPassword';
import {useNavigate} from 'react-router-dom';
import RequestResetForm from "./form/RequestResetForm";
import ConfirmResetForm from "./form/ConfirmationResetForm";

const ResetContainer = styled(Container)(({theme}) => ({
    marginTop: theme.spacing(8)
}));

const ResetPaper = styled(Box)(({theme}) => ({
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
}));

const ResetPassword: React.FC = () => {
    const navigate = useNavigate();
    const {isLoading, error, state, requestPasswordReset, confirmPasswordReset} = useResetPassword();

    const renderStep = () => {
        switch (state.step) {
            case 'request':
                return <RequestResetForm
                    onSubmit={requestPasswordReset}
                    isLoading={isLoading}
                    error={error}
                />;
            case 'verify':
                return <ConfirmResetForm
                    onSubmit={confirmPasswordReset}
                    email={state.email}
                    isLoading={isLoading}
                    error={error}
                />;
            case 'success':
                return (
                    <Box textAlign="center">
                        <Typography variant="h6" color="primary">
                            Password Reset Successful!
                        </Typography>
                        <Typography variant="body1" sx={{mt: 2}}>
                            You can now login with your new password.
                        </Typography>
                        <Box sx={{mt: 3}}>
                            <Button variant="contained" onClick={() => navigate('/login')}>
                                Go to Login
                            </Button>
                        </Box>
                    </Box>
                );
        }
    };

    return (
        <ResetContainer maxWidth="sm">
            <ResetPaper>
                {renderStep()}
            </ResetPaper>
        </ResetContainer>
    );
};

export default ResetPassword;